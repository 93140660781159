import createLucideIcon from '../createLucideIcon';

/**
 * @component @name BookOpenCheck
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjFWNyIgLz4KICA8cGF0aCBkPSJtMTYgMTIgMiAyIDQtNCIgLz4KICA8cGF0aCBkPSJNMjIgNlY0YTEgMSAwIDAgMC0xLTFoLTVhNCA0IDAgMCAwLTQgNCA0IDQgMCAwIDAtNC00SDNhMSAxIDAgMCAwLTEgMXYxM2ExIDEgMCAwIDAgMSAxaDZhMyAzIDAgMCAxIDMgMyAzIDMgMCAwIDEgMy0zaDZhMSAxIDAgMCAwIDEtMXYtMS4zIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/book-open-check
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const BookOpenCheck = createLucideIcon('BookOpenCheck', [
  ['path', { d: 'M12 21V7', key: 'gj6g52' }],
  ['path', { d: 'm16 12 2 2 4-4', key: 'mdajum' }],
  [
    'path',
    {
      d: 'M22 6V4a1 1 0 0 0-1-1h-5a4 4 0 0 0-4 4 4 4 0 0 0-4-4H3a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h6a3 3 0 0 1 3 3 3 3 0 0 1 3-3h6a1 1 0 0 0 1-1v-1.3',
      key: '8arnkb',
    },
  ],
]);

export default BookOpenCheck;
