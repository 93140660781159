import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Slice
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTYuNTg2VjE5YTEgMSAwIDAgMS0xIDFIMkwxOC4zNyAzLjYzYTEgMSAwIDEgMSAzIDNsLTkuNjYzIDkuNjYzYTEgMSAwIDAgMS0xLjQxNCAwTDggMTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/slice
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Slice = createLucideIcon('Slice', [
  [
    'path',
    {
      d: 'M11 16.586V19a1 1 0 0 1-1 1H2L18.37 3.63a1 1 0 1 1 3 3l-9.663 9.663a1 1 0 0 1-1.414 0L8 14',
      key: '1sllp5',
    },
  ],
]);

export default Slice;
